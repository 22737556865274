<template>
  <span class="regular-12 text-black text-start">
    <img
      v-if="value.icon"
      :src="storageUrl + '/' + value.icon"
      class="height-10"
      alt=""
    />
    <span class="px-2">{{ value?.name }}</span>
  </span>
</template>

<script>
export default {
  name: "PaymentMethodGateway",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  computed: {
    storageUrl() {
      return process.env.VUE_APP_FILES;
    },
  },
};
</script>
